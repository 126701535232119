@import url('//hello.myfonts.net/count/3be76b');
@import url('//hello.myfonts.net/count/3c03ab');

/**
 * @license
 * MyFonts Webfont Build ID 3925867, 2020-07-15T04:32:30-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: FF Meta Web Pro Book by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/ff-meta/pro-book/
 *
 * Webfont: FF Meta Web Pro Bold by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/ff-meta/pro-bold/
 *
 *
 * Webfonts copyright: Copyright &amp;#x00A9; 1991-2018 Monotype GmbH. All rights reserved
 *
 * © 2020 MyFonts Inc
*/

@font-face {
  font-family: 'ff-meta-web-pro';
  src: url('fonts/FFMetaWebProBold/font.woff2') format('woff2'),
    url('fonts/FFMetaWebProBold/font.woff') format('woff');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}
/* @font-face {
    font-family: 'FFMetaWebProBook';
    src: url('fonts/FFMetaWebProBook/font.woff2') format('woff2'), url('fonts/FFMetaWebProBook/font.woff') format('woff');
} */

/**
 * @license
 * MyFonts Webfont Build ID 3933099, 2020-08-06T03:46:20-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: FF Meta Web Pro Regular by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/ff-meta/pro-regular/
 *
 * Webfont: FF Meta Web Pro Italic by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/ff-meta/pro-italic/
 *
 * Webfont: FF Meta Web Pro Medium by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/ff-meta/pro-medium/
 *
 *
 * Webfonts copyright: Copyright &amp;#x00A9; 1991-2018 Monotype GmbH. All rights reserved
 *
 * © 2020 MyFonts Inc
*/

@font-face {
  font-family: 'ff-meta-web-pro';
  src: url('fonts/FFMetaWebProMedium/font.woff2') format('woff2'),
    url('fonts/FFMetaWebProMedium/font.woff') format('woff');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'ff-meta-web-pro';
  src: url('fonts/FFMetaWebProItalic/font.woff2') format('woff2'),
    url('fonts/FFMetaWebProItalic/font.woff') format('woff');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: 'ff-meta-web-pro';
  src: url('fonts/FFMetaWebProRegular/font.woff2') format('woff2'),
    url('fonts/FFMetaWebProRegular/font.woff') format('woff');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}
